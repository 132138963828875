<template>
  <div>
    <!-- Tabel -->
    <loading v-if="Loading"/>
    <b-row v-if="!Loading">
      <b-col class="col-6">
        <b-table striped hover :items="listDPOrequest" :fields="dpoFields" show-empty>
          <template v-slot:cell(biodata)="row">
            <!-- <h3 style="text-align: center;" class="wanted-text" >-= {{ row.item.title }} =-</h3><hr/> -->
            <div class="d-flex" @click="showDPOInfo(row.item)">
              <!-- Foto -->
              <div class="wanted-overlay">
                  <img :src="require('@/assets/images/avatars/8.png')" v-if="row.item.imgurl == null && row.item.sex == 'm' || row.item.valid_img == false && row.item.sex == 'm'" style="width: 150px; height: 150px;">
                  <img :src="require('@/assets/images/avatars/11.png')" v-if="row.item.imgurl == null && row.item.sex == 'f'|| row.item.valid_img == false && row.item.sex == 'f'" style="width: 150px; height: 150px;">
                  <img :src="row.item.imgurl" style="width: 150px; height: 150px;" v-if="row.item.imgurl != null && row.item.valid_img == true">
                  <div class="overlay-text">WANTED</div>
              </div>
              <!-- Informasi biodata kedua -->
              <div class="col-3"> <!-- Memberikan lebar tetap menggunakan col-3 -->
                <p><strong>Nama KTP:</strong> <br/><span style="color: yellow;">{{ row.item.fullname }}</span></p>
                <p><strong>Tanggal Lahir:</strong><br/> {{ row.item.dateofbirth }}</p>
              </div>
              <div class="col-12"> <!-- Memberikan lebar tetap menggunakan col-3 -->
                <p><strong>Nomor Telepon:</strong><br/> {{ row.item.phone_number }}</p>
                <p><strong>Periode DPO:</strong> <br/>{{ row.item.periode }}</p>
              </div>
            </div>
          </template>
          <template #empty>
            <div class="text-center my-3">
              Tidak ada data yang tersedia saat ini.
            </div>
          </template>
        </b-table>
      </b-col>
      <b-col class="col-6">
        <b-table striped hover :items="listDPKrequest" :fields="dpkFields" show-empty>
          <template v-slot:cell(biodata)="row">
            <!-- <h3 style="text-align: center;" class="wanted-text" >-= {{ row.item.title }} =-</h3><hr/> -->
            <div class="d-flex" @click="showDPKInfo(row.item)">
              <!-- Foto -->
              <div class="wanted-overlay">
                <img :src="require('@/assets/images/avatars/car1.png')" v-if="row.item.imgurl == null && row.item.valid_img == false" style="width: 150px; border-radius: 10%;" class="mr-3">
                <img :src="row.item.imgurl" style="width: 150px; height: 150px;" v-if="row.item.imgurl != null && row.item.valid_img == true">
                <div class="overlay-text">Arrested</div>
              </div>
              <!-- Informasi biodata kedua -->
              <div class="col-3"> <!-- Memberikan lebar tetap menggunakan col-3 -->
                <p><strong>Plate:</strong> <br/><span style="color: yellow;">{{ row.item.plate }}</span></p>
                <p><strong>Model Name:</strong><br/> {{ row.item.modelname }}</p>
              </div>
              <div class="col-12"> <!-- Memberikan lebar tetap menggunakan col-3 -->
                <p><strong>Periode DPO:</strong> <br/>{{ row.item.periode }}</p>
              </div>
            </div>
          </template>
          <template #empty>
            <div class="text-center my-3">
              Tidak ada data yang tersedia saat ini.
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- Modal -->
    <b-modal v-model="modalShow" title="DPO Detail" size="md" centered hide-footer>
      <loading v-if="LoadingDPO"/>
      <div class="activity-timeline" v-if="!LoadingDPO">
        <div v-for="(activity, index) in activityData" :key="index" class="activity">
          <div class="activity-content">
            <div class="row">
              <!-- Kolom untuk jam (kiri) -->
              <div class="col-auto">
                <div class="text-muted">{{ activity.date }}</div>
                <p>{{ activity.description }}</p>
                <div class="text-muted"><i class="feather icon-user-check"></i> - {{ activity.by }}</div>
              </div>
            </div>
            <!-- Baris baru untuk keterangan timeline -->
            <div class="row">
              <div class="col">
              </div>
            </div>
          </div>
        </div>
        <b-button type="submit" block variant="success" @click="confirmRelease" v-if="isValidUser">Release DPO</b-button>
      </div>
    </b-modal>

    <b-modal v-model="modalShowDPK" title="DPK Detail" size="md" centered hide-footer>
      <loading v-if="LoadingDPO"/>
      <div class="activity-timeline" v-if="!LoadingDPO">
        <div v-for="(activity, index) in activityData" :key="index" class="activity">
          <div class="activity-content">
            <div class="row">
              <!-- Kolom untuk jam (kiri) -->
              <div class="col-auto">
                <div class="text-muted">{{ activity.date }}</div>
                <p>{{ activity.description }}</p>
                <div class="text-muted"><i class="feather icon-user-check"></i> - {{ activity.by }}</div>
              </div>
            </div>
            <!-- Baris baru untuk keterangan timeline -->
            <div class="row">
              <div class="col">
              </div>
            </div>
          </div>
        </div>
        <b-button type="submit" block variant="success" @click="confirmReleaseDPK" v-if="isValidUser">Release DPO</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full"
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect,
    axios
  },
  data() {
    return {
      selectedDPO : null,
      Loading: false,
      LoadingDetail: false,
      LoadingDPO: false,
      infoDPOModal: false,
      modalShowDPK: false,
      infoRelease: false,
      info: [],
      activityData: [],
      dpoFields: [
        {key:"biodata",text:"Data Warga"},
      ],
      dpkFields: [
        {key:"biodata",text:"Data Kendaraan"},
      ],
      modalShow: false,
      listDPOrequest: [],
      listDPKrequest: [],
      selectedDPK: null,
      isValidUser: false,
    };
  },
  methods: {
    init(){
      this.getData();
      this.checkValidAccessButton(4);
    },
    getData() {
      this.Loading = true;
      axios.post("idp/dpo/release-list",{id:null}).then((response) => {
        this.listDPOrequest = response.data.dpo
        this.listDPKrequest = response.data.dpk
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
        this.modalShow = false;
      });
    },
    confirmRelease(row){
      this.$swal({
        title: "Hapus Status DPO?",
        icon: "question",
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ea5455",
        confirmButtonText: "Save",
      }).then((result) => {
        if (result.isConfirmed) {  

          this.LoadingDPO = true
          setTimeout(() => {
            axios.post("idp/user/dpo-release",{id:this.selectedDPO}).then((response) => {
              
              if(response.data.status == 2000){

                this.$swal({
                  title: "Success",
                  icon: "success",
                  background: "black",
                  confirmButtonColor: "#7367f0",
                  confirmButtonText: "Close",
                });

                this.LoadingDPO = false
                this.modalShow = false
                this.init();
              }
            });
          }, 1000);

        } else {
          this.dpoModal = false
        }
      });
      
    },
    showDPOInfo(row){
      axios.post("idp/user/dpo-info-timeline",{id:row.user_dpo_id}).then((response) => {
        this.activityData = response.data.data
        this.selectedDPO = row.user_dpo_id
        this.modalShow = true
      });
    },
    showDPKInfo(row){
      axios.post("idp/user/dpk-info-timeline",{id:row.user_dpk_id}).then((response) => {
        this.activityData = response.data.data
        this.selectedDPK = row.user_dpk_id
        this.modalShowDPK = true
      });
    },
    confirmReleaseDPK(row){
      this.$swal({
        title: "Hapus Status DPK?",
        icon: "question",
        background: "black",
        showCancelButton: true,
        confirmButtonColor: "#7367f0",
        cancelButtonColor: "#ea5455",
        confirmButtonText: "Save",
      }).then((result) => {
        if (result.isConfirmed) {  

          this.LoadingDPO = true
          setTimeout(() => {
            axios.post("idp/user/dpk-release",{id:this.selectedDPK}).then((response) => {
              
              if(response.data.status == 2000){
                this.$swal({
                  title: "Success",
                  icon: "success",
                  background: "black",
                  confirmButtonColor: "#7367f0",
                  confirmButtonText: "Close",
                });
                this.LoadingDPO = false
                this.modalShowDPK = false
                this.init();
              }
            });
          }, 1000);

        } else {
          this.modalShowDPK = false
        }
      });
      
    },
    checkValidAccessButton(id_config){
      axios.post("idp/master/role-config/auth",{id:id_config}).then((response) => {
        this.isValidUser = response.data.valid
      })
    },
  },
  mounted(){
    this.init();
  }
}
</script>
<style scoped>
.activity-timeline {
  margin-top: 20px;
}

.activity {
  display: flex;
  margin-bottom: 20px;
}

.activity-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f5f7;
}

.activity-content {
  margin-left: 20px;
  border-left: 2px solid #007bff;
  padding-left: 20px;
}

.activity-content p {
  margin: 0;
}

.btn-view {
  margin-top: 10px;
}

.modal-tab-content {
  padding: 20px;
}

.wanted-text {
    font-family: 'Impact', sans-serif; /* Gunakan font yang memiliki tampilan tebal dan mencolok */
    font-size: 20px; /* Sesuaikan ukuran font sesuai kebutuhan */
    text-transform: uppercase; /* Ubah teks menjadi huruf besar */
    color: gray; /* Gunakan warna putih untuk teks */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Tambahkan shadow dengan ukuran dan warna yang diinginkan */
}

.wanted-overlay {
    position: relative;
    display: inline-block;
}

.wanted-image {
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
}

@keyframes blinking {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    font-weight: bold;
    color: white; /* Ubah warna teks menjadi merah */
    background-color: yellowgreen;
    padding: 20px 40px;
    border-radius: 5px;
    animation: blinking 1s infinite alternate; /* Terapkan animasi blinking */
}
</style>
